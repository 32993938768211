@defer (when dataLoaded$ | async) {
  @if (!authenticated) {
    <app-login />
  } @else if (authenticated && (shouldShowAgreePage$ | async)) {
    <app-agree />
  } @else if (authenticated && (shouldShowAgreePage$ | async) === false) {
    <div class="page-container" [ngClass]="background">
      @if (showLayout$ | async) {
        <sidebar />
      }

      <div class="page-content">
        @if (showLayout$ | async) {
          <app-header />
          <footer-mobile />
        }

        <main>
          <router-outlet />
        </main>
      </div>
    </div>

    <app-terms-of-use />
    <app-cookie-banner />
  }
} @placeholder {
  <app-spinner />
}
