import { Injectable } from '@angular/core';
import {
  Actions,
  ROOT_EFFECTS_INIT,
  createEffect,
  ofType,
} from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  filter,
  map,
  mergeMap,
  switchMap,
} from 'rxjs/operators';
import { ChatHistoryService } from 'src/app/core/api/chat-history.service';
import {
  IEventConversationCompleted,
  IEventConversationTyping,
  IEventUserJoinedConversationEvent,
  IEventUserLeaveConversationEvent,
  ISuggestion,
  SocketService,
  SuggestionType,
} from 'src/app/core/api/socket.service';

import { Router } from '@angular/router';
import { FeedbackService } from 'src/app/core/api/feedback.service';
import { AuthActions } from 'src/app/store/auth/auth.actions';
import {
  selectAccessToken,
  selectUserId,
} from 'src/app/store/auth/auth.selectors';
import { ChatActions } from './chat.actions';
import {
  selectChatContext,
  selectConversationId,
  selectMessages,
} from './chat.selectors';
import { ContextsService } from 'src/app/core/api/contexts.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ChatEffects {
  constructor(
    private actions$: Actions,
    private socketService: SocketService,
    private router: Router,
    private store: Store,
    private chatHistoryService: ChatHistoryService,
    private feedbackService: FeedbackService,
    private contextsService: ContextsService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {}

  connect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getUserDataSuccess),
      exhaustMap((action) => {
        return this.socketService
          .createConnection(action.accessToken)
          .start()
          .onConnected()
          .pipe(
            map(() => {
              return ChatActions.connected();
            }),
            catchError(() => EMPTY)
          );
      })
    );
  });

  getChatContexts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      concatMap(() => this.contextsService.getContexts()),
      map((res) => ChatActions.setContexts({ contexts: res.templates }))
    );
  });

  createOrConnectToRoom$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ChatActions.getConversationByIdRequest,
        ChatActions.createRoomAndSendMessageRequest
      ),
      switchMap((action) => {
        return this.socketService
          .createRoom((action as any)?.conversationId)
          .onCreatedRoom()
          .pipe(
            map((conversationId) => {
              const conversationFromHistory =
                action.type === '[Chat Component] getConversationByIdRequest';

              const conversationFromHome = !this.router.url.includes('chat');

              if (conversationFromHome) {
                this.router.navigate(['chat']);
              }

              if (
                action.type ===
                '[Chat Component] createRoomAndSendMessageRequest'
              ) {
                return ChatActions.createRoomAndSendMessageSuccess({
                  conversationId,
                  message: action.message,
                });
              }

              if (conversationFromHistory) {
                this.router.navigate(['chat/', action.conversationId]);
              }

              return ChatActions.createdRoom({ conversationId });
            }),
            catchError(() => EMPTY)
          );
      })
    );
  });

  sendMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ChatActions.createRoomAndSendMessageSuccess,
        ChatActions.sendMessageRequest
      ),
      concatLatestFrom(() => [
        this.store.select(selectConversationId),
        this.store.select(selectChatContext),
      ]),
      exhaustMap(([action, conversationId, chatContext]) => {
        const selectedActionFlowOrEmpty =
          action.type === '[Chat Component] sendMessageRequest' &&
          action.actionFlow
            ? [action.actionFlow]
            : [];

        return of(
          this.socketService.sendMessage({
            conversationId,
            question: action.message,
            templateId: chatContext.id,
            connectorId: chatContext.connectorId,
            actionFlow: selectedActionFlowOrEmpty,
          })
        ).pipe(
          map(() => {
            return ChatActions.sendMessageSuccess({ message: action.message });
          }),
          catchError(() => EMPTY)
        );
      })
    );
  });

  listenToConversation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatActions.connected),
      switchMap(() => {
        return this.socketService.onNotify().pipe(
          concatLatestFrom(() => [
            this.store.select(selectConversationId),
            this.store.select(selectMessages),
            this.store.select(selectAccessToken),
          ]),
          filter(([{ event }, currentConversationId]) => {
            const ifSameConversationOrConversationNotStarted =
              event.ConversationId === currentConversationId ||
              !currentConversationId;

            return ifSameConversationOrConversationNotStarted;
          }),
          map(([{ eventName, event, userId }, _, messages, accessToken]) => {
            switch (eventName) {
              case 'ConversationTyping': {
                const conversationTypingEvent =
                  event as IEventConversationTyping;

                return ChatActions.messageReceived({
                  chunk: conversationTypingEvent.Chunk,
                  messageId: conversationTypingEvent.MessageId,
                  question: conversationTypingEvent.Question,
                  responseFormat: conversationTypingEvent.ResponseFormat,
                });
              }

              case 'ConversationCompleted': {
                const conversationCompletedEvent =
                  event as IEventConversationCompleted;
                const firstResponseFromBot = messages.length === 2;

                if (firstResponseFromBot) {
                  this.router.navigate(['chat/', event.ConversationId]);
                }

                return ChatActions.conversationCompleted({
                  conversationId: event.ConversationId,
                  fromInternet:
                    conversationCompletedEvent.Context == 'INTERNET',
                  message: conversationCompletedEvent.Answer || '',
                  totalTokens: 0,
                  prompt: conversationCompletedEvent.Question,
                  accessToken,
                  suggestions: this.orderSuggestions(
                    conversationCompletedEvent.Suggestions || []
                  ),
                  context: conversationCompletedEvent.Context || undefined,
                  widgets: conversationCompletedEvent.Widgets,
                  messageId: conversationCompletedEvent.MessageId,
                  userId,
                  responseFormat: conversationCompletedEvent.ResponseFormat,
                });
              }

              case 'UserJoinedConversationEvent': {
                const userJoinedEvent =
                  event as IEventUserJoinedConversationEvent;

                this.toastr.info(
                  this.translateService.instant(
                    'PAGES.CHAT.CONVERSATION.USER_JOIN',
                    { user: userJoinedEvent.UserName }
                  ),
                  '',
                  {
                    positionClass: 'toast-bottom-left',
                  }
                );

                return ChatActions.userJoinedConversation({
                  id: userJoinedEvent.UserId,
                  authorName: userJoinedEvent.UserName,
                  authorRole: userJoinedEvent.AuthorRole,
                });
              }

              case 'UserLeaveConversationEvent': {
                const userLeavedEvent =
                  event as IEventUserLeaveConversationEvent;

                if (userLeavedEvent.UserName) {
                  this.toastr.info(
                    this.translateService.instant(
                      'PAGES.CHAT.CONVERSATION.USER_LEAVE',
                      { user: userLeavedEvent.UserName }
                    ),
                    '',
                    {
                      positionClass: 'toast-bottom-left',
                    }
                  );
                } else {
                  this.toastr.info(
                    this.translateService.instant(
                      'PAGES.CHAT.CONVERSATION.AGENT_UNAVAILABLE'
                    ),
                    '',
                    {
                      positionClass: 'toast-bottom-left',
                    }
                  );
                }

                return ChatActions.userLeavedConversation({
                  id: userLeavedEvent.UserId,
                });
              }

              default:
                return ChatActions.doNothing();
            }
          })
        );
      })
    );
  });

  getConversationById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatActions.getConversationByIdRequest),
      exhaustMap((action) =>
        this.chatHistoryService
          .getConversationById(action.conversationId)
          .pipe(catchError(() => EMPTY))
      ),
      concatLatestFrom(() => [this.store.select(selectAccessToken)]),
      map(([conversation, accessToken]) => {
        return ChatActions.getConversationByIdSuccess({
          conversation,
          accessToken,
        });
      })
    );
  });

  submitFeedback$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatActions.submitFeedbackRequest),
      concatLatestFrom(() => [
        this.store.select(selectUserId),
        this.store.select(selectConversationId),
      ]),
      mergeMap(([action, userId, conversationId]) => {
        return this.feedbackService
          .submitFeedback({
            ...action.feedback,
            userId,
            conversationId,
          })
          .pipe(
            map(() => ChatActions.submitFeedbackSuccess()),
            catchError(() => EMPTY)
          );
      })
    );
  });

  private orderSuggestions(suggestions: ISuggestion[]) {
    return suggestions
      .sort((a, b) => {
        if (a.Type === SuggestionType.Flow) {
          return -1;
        } else if (b.Type === SuggestionType.Flow) {
          return 1;
        }

        return 0;
      })
      .sort((a, b) => b.Order - a.Order);
  }
}
