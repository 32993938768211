import { createReducer, on } from '@ngrx/store';
import { ChatActions } from './chat.actions';
import { IChatReducer, IMessageInfo, IMessages } from './chat.state';
import { SidebarActions } from '../sidebar/sidebar.actions';
import { Widget, WidgetFromHistory } from 'src/app/core/api/socket.service';

function createMessageObj(
  text: string,
  id: string,
  info?: IMessageInfo
): IMessages {
  return {
    id,
    bot: !!info,
    text,
    info: info,
  };
}

function addWidgetsToMessage(
  widgets: Widget[] | WidgetFromHistory[] | undefined,
  message: string,
  accessToken: string
) {
  if (widgets) {
    const ACTION_WIDGET = 1;
    const actionWidget = widgets.find(
      (w) =>
        (w as Widget).Type === ACTION_WIDGET ||
        (w as WidgetFromHistory).type === ACTION_WIDGET
    );

    if (actionWidget) {
      return (message += `<adaptative-card ac_id="${(actionWidget as Widget).Id || (actionWidget as WidgetFromHistory).id}" accessToken="${accessToken}"/>`);
    }
  }

  return message;
}

function replaceMediaHandlers(message: string, accessToken: string) {
  return message.replaceAll(
    'media-handler',
    `media-handler startRequest="true" accessToken="${accessToken}"`
  );
}

function addWebComponentsToMessage(
  widgets: Widget[] | undefined,
  message: string,
  accessToken: string
) {
  const messageWithMediaHandlers = replaceMediaHandlers(message, accessToken);
  const messageWithWidgetsAndMediaHandlers = addWidgetsToMessage(
    widgets,
    messageWithMediaHandlers,
    accessToken
  );

  return messageWithWidgetsAndMediaHandlers;
}

export const initialState: IChatReducer = {
  showWelcomePage: true,
  isSending: false,
  isBotTyping: false,
  connected: false,
  suggestions: [],
  messages: [],
  conversationId: '',
  isLoadingBotMessage: false,
  chatContext: {
    context: '',
    id: '',
    connectorId: '',
  },
  contexts: [],
  isUploadingFile: false,
  connectedUsers: [],
};

export const chatReducer = createReducer<IChatReducer>(
  initialState,
  on(
    ChatActions.connected,
    (state): IChatReducer => ({
      ...state,
      connected: true,
    })
  ),
  on(
    ChatActions.disconnected,
    (state): IChatReducer => ({
      ...state,
      connected: false,
    })
  ),
  on(
    ChatActions.createdRoom,
    ChatActions.createRoomAndSendMessageSuccess,
    (state, { conversationId }): IChatReducer => ({
      ...state,
      conversationId,
    })
  ),
  on(
    ChatActions.createRoomAndSendMessageSuccess,
    ChatActions.sendMessageRequest,
    (state, { message }) => {
      const fakeId = new Date().toISOString();

      return {
        ...state,
        completedAnswer: false,
        showWelcomePage: false,
        isSending: true,
        messages: [...state.messages, createMessageObj(message, fakeId)],
      };
    }
  ),
  on(ChatActions.sendMessageSuccess, (state, { message }): IChatReducer => {
    const fakeId = new Date().toISOString();
    const outdatedMessages = state.messages[state.messages.length - 1].bot;
    const echoMessages = [...state.messages, createMessageObj(message, fakeId)];

    return {
      ...state,
      isSending: false,
      isLoadingBotMessage: true,
      messages: outdatedMessages ? echoMessages : state.messages,
    };
  }),
  on(
    ChatActions.messageReceived,
    (state, { chunk, messageId, question, responseFormat }) => {
      const messageIndex = state.messages.findIndex(
        (message) => message.id === messageId
      );
      const messageNotCreated = messageIndex === -1;

      if (messageNotCreated) {
        const isMissingUserQuestion =
          state.messages[state.messages.length - 1].text !== question;
        const newMessages = [...state.messages];

        if (isMissingUserQuestion) {
          newMessages.push(createMessageObj(question, `${messageId}-user`));
        }

        return {
          ...state,
          messages: [
            ...newMessages,
            createMessageObj(chunk, messageId, {
              fromInternet: false,
              totalTokens: 0,
              prompt: '',
              feedbackLike: false,
              hasFeedback: false,
              responseFormat,
              completedAnswer: false,
            }),
          ],
        };
      } else {
        const updatedMessage = { ...state.messages[messageIndex] };
        updatedMessage.text = updatedMessage.text + chunk;

        const updatedMessages = state.messages.map((message, i) => {
          if (messageIndex === i) {
            return updatedMessage;
          }

          return message;
        });

        return {
          ...state,
          isBotTyping: true,
          isLoadingBotMessage: false,
          messages: updatedMessages,
        };
      }
    }
  ),
  on(
    ChatActions.conversationCompleted,
    (
      state,
      {
        conversationId,
        fromInternet,
        totalTokens,
        prompt,
        accessToken,
        suggestions,
        context,
        message,
        widgets,
        messageId,
        userId,
        responseFormat,
      }
    ) => {
      const messageIndex = state.messages.findIndex(
        (message) => message.id === messageId
      );
      const messageNotCreated = messageIndex === -1;

      if (messageNotCreated) {
        const messageWithWebComponents = addWebComponentsToMessage(
          widgets,
          message,
          accessToken
        );

        const connectedUser = state.connectedUsers.find((u) => u.id === userId);

        return {
          ...state,
          isBotTyping: false,
          isLoadingBotMessage: false,
          messages: [
            ...state.messages,
            createMessageObj(messageWithWebComponents, messageId, {
              fromInternet,
              totalTokens,
              prompt,
              feedbackLike: false,
              hasFeedback: false,
              context,
              authorName: connectedUser?.authorName,
              authorRole: connectedUser?.authorRole,
              responseFormat,
              completedAnswer: true,
            }),
          ],
        };
      } else {
        const updatedMessage = { ...state.messages[messageIndex] };

        updatedMessage.text = addWebComponentsToMessage(
          widgets,
          updatedMessage.text,
          accessToken
        );

        if (updatedMessage.info) {
          updatedMessage.info = {
            ...updatedMessage.info,
            context,
            prompt,
            fromInternet,
            totalTokens,
            completedAnswer: true,
          };
        }

        const updatedMessages = state.messages.map((message, i) => {
          if (messageIndex === i) {
            return updatedMessage;
          }

          return message;
        });

        return {
          ...state,
          isBotTyping: false,
          conversationId,
          suggestions,
          messages: updatedMessages,
        };
      }
    }
  ),
  on(
    ChatActions.conversationWithError,
    (state): IChatReducer => ({
      ...state,
      isBotTyping: false,
      isLoadingBotMessage: false,
      suggestions: [],
    })
  ),
  on(
    ChatActions.resetSuggestions,
    (state): IChatReducer => ({
      ...state,
      suggestions: [],
    })
  ),
  on(
    ChatActions.getConversationByIdRequest,
    (state, { conversationId }): IChatReducer => {
      const isFirstResponse = state.messages.length === 2;

      return {
        ...state,
        conversationId,
        isBotTyping: false,
        isLoadingBotMessage: false,
        suggestions: isFirstResponse ? state.suggestions : [],
      };
    }
  ),
  on(
    ChatActions.getConversationByIdSuccess,
    (state, { conversation, accessToken }) => {
      const hasConversation = conversation.length > 0;

      return {
        ...state,
        connectedUsers: conversation
          .filter((c) => c.from.authorRole)
          .map((c) => ({
            id: c.from.id,
            authorName: c.from.authorName,
            authorRole: c.from.authorRole,
          })),
        showWelcomePage: false,
        messages: hasConversation
          ? conversation.reduce<IMessages[]>((acc, message) => {
              const messageWithWebComponents = addWebComponentsToMessage(
                message.widgets || undefined,
                message.answer || '',
                accessToken
              );

              const messages = [...acc];

              if (message.question) {
                messages.push(
                  createMessageObj(message.question, `${message.id}-user`)
                );
              }

              messages.push(
                createMessageObj(messageWithWebComponents, message.id, {
                  fromInternet: message.context === 'INTERNET',
                  totalTokens: message.totalTokens || 0,
                  prompt: message.question,
                  feedbackLike: !!message.feedbacks[0]?.liked,
                  hasFeedback: !!message.feedbacks.length,
                  context: message.context,
                  authorName: message.from.authorName,
                  authorRole: message.from.authorRole,
                  responseFormat: message.responseFormat,
                  completedAnswer: true,
                })
              );

              return messages;
            }, [])
          : state.messages,
      };
    }
  ),
  on(ChatActions.submitFeedbackRequest, (state, action): IChatReducer => {
    const feedbackLike = action.feedback.liked;
    const messageIndex = state.messages.findIndex(
      (message) => message.id === action.feedback.messageId
    );

    if (messageIndex) {
      const messages = state.messages.map((message, index) => {
        if (index === messageIndex && message.info) {
          return createMessageObj(message.text, message.id, {
            ...message.info,
            feedbackLike,
            hasFeedback: true,
          });
        }

        return message;
      });

      return {
        ...state,
        messages,
      };
    }

    return state;
  }),
  on(
    ChatActions.setChatContext,
    (state, { context }): IChatReducer => ({
      ...state,
      chatContext: context,
    })
  ),
  on(
    ChatActions.reset,
    SidebarActions.deleteAllHistorySuccess,
    SidebarActions.deleteHistoryItemSuccess,
    (state): IChatReducer => ({
      ...initialState,
      contexts: state.contexts,
      connected: true,
    })
  ),
  on(
    ChatActions.createRoomAndSendMessageRequest,
    (state): IChatReducer => ({
      ...initialState,
      chatContext: state.chatContext,
      contexts: state.contexts,
      connected: true,
    })
  ),
  on(
    ChatActions.isUploadingFile,
    (state): IChatReducer => ({
      ...state,
      isUploadingFile: true,
    })
  ),
  on(ChatActions.uploadCompleted, (state): IChatReducer => {
    const personalRagContext = state.contexts.find(
      (c) => c.context === 'PERSONAL'
    );

    return {
      ...state,
      isUploadingFile: false,
      chatContext: personalRagContext ? personalRagContext : state.chatContext,
    };
  }),
  on(
    ChatActions.setContexts,
    (state, { contexts }): IChatReducer => ({
      ...state,
      contexts,
    })
  ),
  on(
    ChatActions.userJoinedConversation,
    (state, { id, authorName, authorRole }): IChatReducer => ({
      ...state,
      connectedUsers: [...state.connectedUsers, { id, authorName, authorRole }],
    })
  ),
  on(
    ChatActions.userLeavedConversation,
    (state, { id }): IChatReducer => ({
      ...state,
      connectedUsers: state.connectedUsers.filter((u) => u.id !== id),
    })
  )
);
