import { ErrorHandler, NgModule } from '@angular/core';

import {
  CommonModule,
  HashLocationStrategy,
  LocationStrategy,
} from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';

import { HIGHLIGHT_OPTIONS, HighlightModule } from 'ngx-highlightjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { ErrorHandlerService } from './core/services/error-handler.service';
import { LoggingService } from './core/services/logging.service';
import { APIMInterceptor } from './interceptors/apim.interceptor';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { FooterMobileComponent } from './components/footer-mobile/footer-mobile.component';
import { StoreModule } from './store/store.module';
import { HeaderModule } from './components/header/header.module';
import { MatButtonModule } from '@angular/material/button';
import { SidebarModule } from './components/sidebar/sidebar.module';
import { TermsOfUseModule } from './components/terms-of-use/terms-of-use.module';
import { CookieBannerModule } from './components/cookie-banner/cookie-banner.module';
import { AgreeModule } from './pages/agree/agree.module';
import { LoginModule } from './pages/login/login.module';
import { HeaderInterceptor } from './interceptors/header.interceptor';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { NgxPermissionsModule } from 'ngx-permissions';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { CLIPBOARD_OPTIONS, MarkdownModule } from 'ngx-markdown';
import { ClipboardButtonComponent } from './components/clipboard-button/clipboard-button.component';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent, MsalRedirectComponent],
  imports: [
    CommonModule,
    HighlightModule,
    AppRoutingModule,
    BrowserModule,
    FooterMobileComponent,
    StoreModule,
    AuthModule,
    SidebarModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    HeaderModule,
    MatButtonModule,
    TermsOfUseModule,
    CookieBannerModule,
    AgreeModule,
    LoginModule,
    NgxPermissionsModule.forRoot(),
    SpinnerComponent,
    MarkdownModule.forRoot({
      clipboardOptions: {
        provide: CLIPBOARD_OPTIONS,
        useValue: {
          buttonComponent: ClipboardButtonComponent,
        },
      },
    }),
  ],
  providers: [
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        fullLibraryLoader: () => import('highlight.js'),
      },
    },
    { provide: HTTP_INTERCEPTORS, useClass: APIMInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    {
      provide: LoggingService,
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    CommonModule,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
