import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-clipboard-button',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './clipboard-button.component.html',
  styleUrl: './clipboard-button.component.scss',
})
export class ClipboardButtonComponent {
  private COPY = 'COMPONENTS.CLIPBOARD_BUTTON.COPY';
  private COPIED = 'COMPONENTS.CLIPBOARD_BUTTON.COPIED';

  label = this.COPY;

  toggleCopyLabel() {
    this.label = this.COPIED;

    setTimeout(() => {
      this.label = this.COPY;
    }, 1500);
  }
}
