<div class="container" [ngClass]="{ expanded: isOpen }">
  <nav class="sidebar">
    <div class="section">
      <button class="icon-button" (click)="toggle()">
        @if (isOpen) {
          <i class="material-icons">first_page</i>
        } @else {
          <i class="material-icons">menu</i>
        }
        <span [ngClass]="{ hidden: !isOpen }">{{
          'COMPONENTS.SIDEBAR.CLOSE' | translate
        }}</span>
      </button>

      <a class="icon-button" [routerLink]="['/home']">
        <i class="material-icons">home</i>
        <span [ngClass]="{ hidden: !isOpen }">{{
          'COMPONENTS.SIDEBAR.HOME' | translate
        }}</span>
      </a>

      <button
        class="icon-button"
        (click)="goBack()"
        [disabled]="(hasHistory$ | async) === false"
      >
        <i class="material-icons">arrow_back</i>
        <span [ngClass]="{ hidden: !isOpen }">{{
          'COMPONENTS.SIDEBAR.BACK' | translate
        }}</span>
      </button>

      <a [routerLink]="['/chat']" class="icon-button" (click)="resetChat()">
        <i class="material-icons">add_circle_outline</i>
        <span [ngClass]="{ hidden: !isOpen }">{{
          'COMPONENTS.SIDEBAR.NEW_CHAT' | translate
        }}</span>
      </a>
    </div>

    <ng-template [ngxPermissionsOnly]="['Rag:Creator']">
      <div class="section">
        <a class="icon-button" [routerLink]="['/rag/upload']">
          <i class="material-icons">cloud_upload</i>
          <span [ngClass]="{ hidden: !isOpen }">RAG</span>
        </a>
      </div>
    </ng-template>

    <!-- <div class="section">
    <a class="icon-button" [routerLink]="['/authorization']">
      <i class="material-icons">how_to_reg</i>
      <span [ngClass]="{ hidden: !isOpen }">Authorization</span>
    </a>
  </div> -->

    <div class="section">
      <a class="icon-button" routerLink="/legal-information">
        <i class="material-icons-outlined">privacy_tip</i>
        <span [ngClass]="{ hidden: !isOpen }">{{
          'COMPONENTS.SIDEBAR.LEGAL_INFO' | translate
        }}</span>
      </a>
    </div>

    <div class="logo">
      @if (isOpen) {
        <img
          src="assets/images/logo-expanded-transparent.png"
          alt="ABI Logo"
          width="126"
          height="46"
        />
      } @else {
        <img
          src="assets/images/logo-transparent.png"
          alt="ABI Logo"
          width="36"
          height="38"
        />
      }
    </div>
  </nav>
</div>

@if (urlIncludesChat) {
  <history />
}
